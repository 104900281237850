h1,
h2 {
  font-family: Lato;
}

html,
body {
  font-family: Monospace;
  background-color: #000000;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#canvas {
  width: 100%;
  height: 100%;
  background-color: #000;
}
